<template>
  <el-card v-loading="loading" shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;">
    <el-row :gutter="20">
      <el-col style="text-align: right" :span="24">
        <el-button size="small" @click="clearCashierQrcode" type="info">清理核销二维码缓存</el-button>
      </el-col>
      <el-col :span="12">
        <el-form  :model="form" label-width="9rem" style="padding: 2rem;">
          <el-form-item label="系统名称">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="logo" style="width: 30rem">
            <y_upload_img v-model="form.logo"></y_upload_img>
          </el-form-item>
          <el-form-item label="腾讯地图key">
            <el-input v-model="form.tx_map_key"></el-input>
          </el-form-item>
          <el-form-item label="智能拉黑">
            <el-radio v-model="form.black_auto" :label="1">关闭</el-radio>
            <el-radio v-model="form.black_auto" :label="2">开启</el-radio>
          </el-form-item>
          <el-form-item label="旧数据微信同步修正">
            <el-radio v-model="form.sync_official_revise" :label="false">关闭</el-radio>
            <el-radio v-model="form.sync_official_revise" :label="true">开启</el-radio>
            <div style="line-height: 1rem">
              <div class="y-desc">此功能仅限系统已产生公众号用户数据后才配置开放平台时使用</div>
              <div class="y-desc">开启此项时须确保公众号、小程序已配置开放平台，否则公众号端将无法正常使用</div>
              <div class="y-desc">修正后请及时关闭</div>
            </div>
          </el-form-item>
          <el-form-item label="黑名单跳转链接">
            <el-input v-model="form.black_url"></el-input>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12">
        <el-form :model="form" label-width="8rem" style="padding: 2rem;">
          <el-form-item label="支付邀请人提示">
            <el-switch v-model="form.invite_from_show"></el-switch>
          </el-form-item>
          <el-form-item label="自助核销">
            <el-radio v-model="form.cashier_custom_enable" :label="false">关闭</el-radio>
            <el-radio v-model="form.cashier_custom_enable" :label="true">开启</el-radio>
          </el-form-item>
          <el-form-item label="商家入驻顶部图片">
            <y_upload_img v-model="form.set_in_banner"></y_upload_img>
          </el-form-item>
          <el-form-item label="首页分享标题">
            <el-input v-model="form.index_share_title"></el-input>
          </el-form-item>
          <el-form-item label="首页分享描述">
            <el-input v-model="form.index_share_desc"></el-input>
          </el-form-item>
          <el-form-item label="首页分享图片" style="width: 30rem">
            <y_upload_img v-model="form.index_share_logo"></y_upload_img>
          </el-form-item>
          <el-form-item >
            <el-button @click="submit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import y_upload_img from "@/components/y_upload_img";
export default {
  name: "index",
  components:{
    y_upload_img
  },
  data(){
    return{
      form:{
        name:"",
        logo:"",
        tx_map_key:"",
        black_auto:1,
        black_url:"",
        index_share_title:"",
        index_share_desc:"",
        index_share_logo:"",
        sync_official_revise:false,
        set_in_banner:"",
        invite_from_show:false,
        cashier_custom_enable:false,
      },
      loading:false,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    clearCashierQrcode(){
      this.loading = true;
      this.$api.system.clearCashierQrcodeCache().then(()=>{
        this.$message.success("清理完成");
        this.loading = false;
      })
    },
    load(){
      this.loading = true;
      this.$api.account.info().then(res=>{
        this.form = res.uni_account
        // this.form.name = res.uni_account.name;
        // this.form.logo = res.uni_account.logo;
        // this.form.tx_map_key = res.uni_account.tx_map_key;
        // this.form.black_auto = res.uni_account.black_auto;
        // this.form.black_url = res.uni_account.black_url;
        // this.form.index_share_title = res.uni_account.index_share_title;
        // this.form.index_share_desc = res.uni_account.index_share_desc;
        // this.form.index_share_logo = res.uni_account.index_share_logo;
        this.loading = false;
      })
    },
    submit(){
      this.form.black_auto = parseInt(this.form.black_auto);
      this.$api.account.uniEdit(this.form).then(()=>{
        this.$message.success("操作成功");
      })
    }
  }
}
</script>

<style scoped>

</style>